export const queryKeys = {
  assetCalendar: 'assetCalendar',
  accessToken: 'accessToken',
  assetsOptions: 'assetsOptions',
  portalInfo: 'portalInfo',
  cities: 'cities',
  partnerInfo: 'partnerInfo',
  partnerAssets: 'partnerAssets',
  partnerLocations: 'partnerLocations',
  promotion: 'promotion',
};
