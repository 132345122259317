import { ReactElement } from "react";
import loadingImg from 'assets/images/loading.png';

export function Spinner():ReactElement {
    return (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center z-10 bg-[#FFFFFFB3]">
        <img
          src={loadingImg}
          alt='loading...'
          width={200}
          height={200}
        />
      </div>
    );
}