import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { setAxiosAuth } from 'utils';
import { promoApi } from 'core/api/promo.api';

export const useGetPromotion = ({ accessToken = '' }) => {
  setAxiosAuth(accessToken);

  return useQuery({
    queryKey: [queryKeys.promotion],
    queryFn: promoApi.getPromotions,
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(accessToken),
  });
}
