
export const addTrackingCode = (env) => {
  if(undefined !== env && env.startsWith('prod') ){
	  //added on 23Sep
	  const script = document.createElement("noscript");
	  script.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-575QFC4G' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
	  document.head.appendChild(script);
	  
	  //added on 23Sep
	  const script1 = document.createElement("script");
	  script1.innerHTML ="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-575QFC4G');";
	  document.head.appendChild(script1);
	  
	  //old
	  const script2 = document.createElement("script");
	  script2.src = "https://www.googletagmanager.com/gtag/js?id=G-2VC15ZKZ49";
	  script2.async = true;
	  document.head.appendChild(script2);
	  
	  //old
	  const script3 = document.createElement("script");
	  script3.innerHTML ="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-2VC15ZKZ49');";
	  document.head.appendChild(script3);
	  
  }
}

export const triggerConfirmEvent = (env, bookingInfo,customerInfo,equipInfo,equipFee, total) => {
  if(undefined !== env && env.startsWith('prod') ){
	  const script1 = document.createElement("script");
	  
	  script1.innerHTML ="gtag('event', 'conversion', {'send_to': 'AW-16548202652/AGQyCMKqkNUZEJyR5tI9','value': "+total+",'currency': 'USD',"+
	  	"'transaction_id': "+bookingInfo?.referenceId+"});";
	  document.head.appendChild(script1);

  	  triggerConfirmHeapEvent (env, bookingInfo,customerInfo,equipInfo,equipFee);
  }
}
export const triggerConfirmHeapEvent = (env, bookingInfo,customerInfo,equipInfo,equipFee) => {
	  const script1 = document.createElement("script");
	  let platformFee = equipFee?.cost?.platform +equipFee?.cost?.platformCustomer + equipFee?.cost?.platformProcess;  
	  let partnerGrandTotal = equipFee?.subTotal + equipFee?.tax?.taxTotal - equipFee?.discountTotal;
	  let custGrandTotal = equipFee?.grandTotal +equipFee?.cost?.platformCustomer + equipFee?.cost?.platformProcess;

	  let html= "heap.track('Checkout Completed', {"+
    	"bookingId: "+bookingInfo?.referenceId+","+
	    "customerId: "+customerInfo?.referenceId+","+
	    "partnerId: "+equipInfo?.partner?.identifier+","+
	    "locationId: "+bookingInfo?.location?.referenceId+","+
	    "pickupDate: "+bookingInfo?.pickupDate+","+
	    "pickupTime: "+bookingInfo?.pickupTime+","+
	    "returnDate: "+bookingInfo?.returnDate+","+
	    "returnTime: "+bookingInfo?.returnTime+","+
	    "durationHours: "+bookingInfo?.rentalHour+","+
	    "durationDays: "+bookingInfo?.rentalDay+","+
	    "durationWeeks: "+bookingInfo?.rentalWeek+","+
	    "durationMonths: "+bookingInfo?.rentalMonth+","+
	    "timezone: 'PST',"+
//	    "discountApplied: true, "+
	//    "discountCode: 'SUMMER2024',"+
	    "discountAmount: "+equipFee?.discountTotal+","+
	    "platformFees: "+platformFee+",";
	    if(platformFee >0){
		    html+="platformFeesBreakdown: [";
		    if(equipFee?.cost?.platformCustomer > 0)
		    	    html +="{ type: 'Customer Platform Fee', amount: "+equipFee?.cost?.platformCustomer+" },";
		    if(equipFee?.cost?.platformProcess > 0)
		    	    html +="{ type: 'Payment Processing Fee', amount: "+equipFee?.cost?.platformProcess+" },";
		    if(equipFee?.cost?.platform > 0)
		    	    html +="{ type: 'Partner Platform Fee', amount: "+equipFee?.cost?.platform+" },";
		    html+="],";
	    }
	    html +="customerGrandTotal: "+(custGrandTotal.toLocaleString(undefined, { maximumFractionDigits: 2 }))+","+
	    "partnerGrandTotal: "+(partnerGrandTotal.toLocaleString(undefined, { maximumFractionDigits: 2 }))+","+
	    "currency: 'USD',"+
	    "equipment: [{ "+
	            "id: "+equipInfo?.referenceId+", "+
	            "name: "+equipInfo?.name+", "+
	            "quantity: 1,"+
	            "rentalRates: {";
				 if(equipFee?.rateMap?.DAY?.fee > 0)
					html +="daily: "+equipFee?.rateMap?.DAY.fee+",";
				 if(equipFee?.rateMap?.WEEK?.fee > 0)
					html +="weekly: "+equipFee?.rateMap?.WEEK.fee+",";
				 if(equipFee?.rateMap?.MONTH?.fee > 0)
					html +="monthly: "+equipFee?.rateMap?.MONTH.fee+",";
				 if(equipFee?.rateMap?.HOUR?.fee >0)
					html +="hourly: "+equipFee?.rateMap?.HOUR.fee+",";
		html +=		"}"+
	        "}]"+
		"});";
	  script1.innerHTML=html;
	  document.head.appendChild(script1);
}
export const triggerQuoteRequestEvent = (env, eventName, pickupDate, returnDate, duration, city, category) => {
  if(undefined !== env && env.startsWith('prod') ){
	  const script1 = document.createElement("script");
	  
	  script1.innerHTML= "heap.track('Quote Request Form Displayed', {"+
    	"formDisplayContext: "+eventName+", "+
	    "requestedPickupDate: "+pickupDate+", "+
	    "requestedReturnDate: "+returnDate+", "+
	    "durationDays: "+duration+", "+
	    "requestedCity: "+city+", "+
	    "requestedCategory: "+category+", "+
	    "source: 'MARKETPLACE',"+
	    "displayedOn: new Date().toISOString()});";
	  document.head.appendChild(script1);
  }
}
export const triggerQuoteSubmitEvent = (env, pickupDate, returnDate, duration, city, category,isRental,isPartner) => {
  if(undefined !== env && env.startsWith('prod') ){
	  const script1 = document.createElement("script");
	  
	  script1.innerHTML= "heap.track('Quote Request Submitted', {"+
	    "requestedPickupDate: "+pickupDate+", "+
	    "requestedReturnDate: "+returnDate+", "+
	    "durationDays: "+duration+", "+
	    "requestedCity: "+city+", "+
	    "requestedType: "+category+", "+
	    "isRental: "+isRental+", "+
	    "isPartner: "+isPartner+", "+
	    "source: 'MARKETPLACE',"+
	    "receivedOn: new Date().toISOString()});";
	  document.head.appendChild(script1);
  }
}


