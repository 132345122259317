import http from './httpService';

class PromoApi {
  headers = {
    'accept': 'application/json, text/plain, */*',
    'content-type': 'application/json',
  };

  async getPromotions() {
    return http.get(`${process.env.REACT_APP_BASE_URL ?? ''}promotion/DISCOUNT?portalType=MARKETPLACE`);
  }

  async recordPromoRequest(data: any) {
    return http.post(`${process.env.REACT_APP_BASE_URL ?? ''}promotion`, JSON.stringify(data), {
      headers: this.headers,
    });
  }

}

export const promoApi = new PromoApi();
