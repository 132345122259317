import { useEffect, useState, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { DiscountModal } from 'components/discount-modal/discount-modal';
import { DISCOUNT_MODAL_SHOWN_AT_KEY } from 'core/constants/constant';
import { SettingContext } from 'core/context/SettingContext';
import { useGetAccessToken, useGetPromotion } from 'hooks/api';
import dayjs from 'dayjs';

const canShowModal = () => {
  const timestamp = parseInt(`0${localStorage.getItem(DISCOUNT_MODAL_SHOWN_AT_KEY)}`);
  const lastShownDayJs = dayjs(new Date(timestamp));

  if (!timestamp || lastShownDayJs.add(7,'days').isBefore(dayjs())) {
    return true;
  }

  return false;
}

export function PageTracker() {
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const timeSpentRef = useRef(0);
  const pageViewsCount = useRef(0);
  const loopTimeoutIdRef = useRef<NodeJS.Timeout>();
  const modalTimeoutIdRef = useRef<NodeJS.Timeout>();

  const { searchHasResult, quoteIsOpen, quoteSubmitted } = useContext(SettingContext);

  const location = useLocation();
  const { data: accessTokenData } = useGetAccessToken();
  const { data: promotion } = useGetPromotion({ accessToken: accessTokenData?.data?.accessToken });

  // Track page views by updating state when the route changes
  useEffect(() => {
    pageViewsCount.current += 1;
  }, [location.pathname]);

  // Track time spent on the website
  useEffect(() => {
    // Show modal based on conditions and if it hasn't been shown in the past 7 days
    const modalStatus = () => {
      timeSpentRef.current += 1;

      if (quoteSubmitted) {
        clearTimeout(modalTimeoutIdRef.current);
        clearTimeout(loopTimeoutIdRef.current);
        return;
      }

      if (quoteIsOpen || !searchHasResult) {
        clearTimeout(modalTimeoutIdRef.current);
        loopTimeoutIdRef.current = setTimeout(modalStatus, 1000);
        return;
      }

      if (
        pageViewsCount.current > 1
        && timeSpentRef.current >= 30
        && canShowModal()
      ) {
        modalTimeoutIdRef.current = setTimeout(() => {
          setShowDiscountModal(true);
          localStorage.setItem(DISCOUNT_MODAL_SHOWN_AT_KEY, new Date().getTime().toString());
        }, 10000);
        return;
      }

      loopTimeoutIdRef.current = setTimeout(modalStatus, 1000);
    }

    modalStatus();

    return () => clearTimeout(loopTimeoutIdRef.current);
  }, [searchHasResult, quoteIsOpen, quoteSubmitted]);

  return (
    Boolean(promotion) && (
      <DiscountModal
        promotion={promotion}
        onClose={() => setShowDiscountModal(false)}
        open={showDiscountModal}
      />
    )
  );
}
